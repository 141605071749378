import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import stammdatenService from './services/stammdatenService.js';
import { filterListeActiveMA, formatNumber2Decimals, renderFilterListe } from './util.js';
import schichtService from './services/schichtService.js';
import { Egfz } from '../../../../domain/konstanten/Egfz.js';
import { ZeitTyp } from '../../../../domain/konstanten/ZeitTyp.js';
import { feiertagFilter } from '../../../util/util.js';
import basedataService from './services/basedataService.js';

window.myHandlers = window.myHandlers || {};
window.myVars = window.myVars || {};
window.myHandlers.filterMonatsuebersichtList = filterMonatsuebersichtList;
window.myHandlers.sortMonatsuebersicht = sortMonatsuebersicht;
window.myVars.monatsuebersichtSortType = 0;

/**
 * Rendert die Monatsünbersicht für das aktuelle Monatsdatum
 */
async function ladeMonatsuebersicht() {
    document.body.style.cursor = 'wait';
    const zeitraum = window.myVars.aktuellesMonatsDatum;
    await renderFilterListe(filterMonatsuebersichtList);
    // IST und Sollzeiten (Soll nur heute und Zukunft!)
    const schichtenMonat = await schichtService.leseAlleSchichtenMonatsuebersicht(zeitraum.format('YYYY-MM-DD'), stammdatenService.aktuelleBetriebsstaette);
    await renderMitarbeiter(zeitraum, schichtenMonat);
    // wir müssen die Monatstage nach den Zeilen machen für die Sonntagsmarkierungen...
    await renderMonatstage(zeitraum);
    filterMonatsuebersichtList();
    document.body.style.cursor = 'default';
}

/**
 * Damit Filter kombinierbar sind, müssen wir immer beide anwenden.
 */
function filterMonatsuebersichtList() {
    filterPosten();
    filterEGFZ();
}

function filterPosten() {
    // filter posten
    const aktivePosten = [];
    // sammle alle aktiven Posten
    document.querySelectorAll('.tu-filter-child').forEach((child) => {
        if (child.querySelector('.tu-filter-child-input').checked) {
            aktivePosten.push(child.id);
        }
    });
    document.querySelectorAll('.grid-item.grid-row').forEach((item) => {
        // todo: check multiple posten...
        item.style.display = !aktivePosten.some((posten) => item.getAttribute('data-posten').split(',').includes(posten)) ? item.classList.add('hidden') : item.classList.remove('hidden');
    });
}

function filterEGFZ() {
    // überprüfe Filterauswahl
    const filterArbeit = document.getElementById('mu-filter-arbeit').checked;
    const filterDienst = document.getElementById('mu-filter-dienst').checked;
    const filterUrlaubOffen = document.getElementById('mu-filter-u-offen').checked;
    const filterUrlaubGenehmigt = document.getElementById('mu-filter-u-genehmigt').checked;
    const filterKrank = document.getElementById('mu-filter-krank').checked;
    const filterSchule = document.getElementById('mu-filter-schule').checked;
    const filterEGFZ = document.getElementById('mu-filter-egfz').checked;
    const filterLeer = document.getElementById('mu-filter-leer').checked;
    const aktiveItems = [...document.querySelectorAll('.grid-item.grid-row:not(.hidden)')];

    function filterCheck(item) {
        let filterMatch = false;
        filterMatch = filterArbeit ? filterMatch || item.getAttribute('data-arbeit') == '1' : filterMatch;
        filterMatch = filterDienst ? filterMatch || item.getAttribute('data-dienst') == '1' : filterMatch;
        filterMatch = filterUrlaubOffen ? filterMatch || item.getAttribute('data-urlaub') == '0' : filterMatch;
        filterMatch = filterUrlaubGenehmigt ? filterMatch || item.getAttribute('data-urlaub') == '1' : filterMatch;
        filterMatch = filterKrank ? filterMatch || item.getAttribute('data-krank') == '1' : filterMatch;
        filterMatch = filterSchule ? filterMatch || item.getAttribute('data-schule') == '1' : filterMatch;
        filterMatch = filterEGFZ ? filterMatch || item.getAttribute('data-egfz') == '1' : filterMatch;
        return filterMatch;
    }

    // Wir iterieren durch alle Items, und überspringen die row wenn mind. 1 Item die Filterkriterien erfüllt...
    let currentRow = 0;
    let anzahlMA = 0;
    const rowLength = 35;
    let emptyRow = [];

    for (let i = 0; i < aktiveItems.length; i++) {
        const currentItem = aktiveItems[i];
        const filterMatch = filterCheck(currentItem);
        // Leere items merken, die ersten 4 Spalten sind immer befüllt
        if (currentItem.innerHTML == '' || (i+1) % rowLength <= 4) {
            emptyRow.push(currentItem);
        }
        // Wenn wir den Filter erfüllen können wir zur nächsten Zeile springen
        if (filterMatch) {
            currentRow++;
            anzahlMA++;
            i = currentRow * rowLength - 1; // -1 da i++ bei continue eins drauf addiert wird in der nächsten Iteration
            emptyRow = [];
            continue;
        }
        // Wenn wir am Ende der Zeile sind, wird die Zeile versteckt (außer ganz Leere Zeile mit Leer Filter aktiv)
        if ((i+1) % rowLength === 0) {
            if (!filterLeer || emptyRow.length !== rowLength) {
                let sibling = currentItem;
                for (let j = 0; j < rowLength;  j++) {
                    sibling.classList.add('hidden');
                    sibling = sibling.previousElementSibling;
                }
            } else {
                anzahlMA++;
            }
            currentRow++;
            emptyRow = [];
        }
    }
    document.getElementById('mu-anzahl-ma').innerText = `(${anzahlMA})`;
}

/**
 * Rendert den Header der Monatsübersicht mit Datum
 * @param {dayjs} zeitraum 
 */
async function renderMonatstage(zeitraum) {
    const feiertage = await basedataService.holeFeiertageBasedataProvider();
    const aktuelleBS = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === stammdatenService.aktuelleBetriebsstaette);
    const feiertageBundesland = _.isEmpty(aktuelleBS) ? feiertage : feiertagFilter(feiertage, aktuelleBS.Bundesland, aktuelleBS.Feiertage);

    const daysInMonth = zeitraum.daysInMonth();
    const datumLegend = document.querySelectorAll('.grid-legend.grid-datum');
    const zeilenElemente = [...document.querySelectorAll('.grid-item:not(.grid-legend, .grid-filter)')];
    const zeilenAnzahl = zeilenElemente.length / 35;
    const sonntagIndices = [];
    const feiertagIndices = [];
    for (let i = 0; i < daysInMonth; i++) {
        const tmpDate = zeitraum.startOf('month').add(i, 'days');
        const tmpElem = datumLegend[i];
        tmpElem.innerText = `${tmpDate.format('dd')}\n${i+1}.`;
        // Sonntage merken
        if (sonntagIndices.length <= 0 && tmpDate.day() == 0) {
            for (let sonntag = tmpDate.date(); sonntag <= daysInMonth; sonntag+=7) {
                sonntagIndices.push(sonntag + 3);
            }   
        }
        // Feiertage merken
        const feiertag = feiertageBundesland.find((feiertag) => dayjs(feiertag.date).isSame(tmpDate));
        if (feiertag) {
            feiertagIndices.push(_.extend(feiertag, { index: tmpDate.date() + 3 }));
        }
    }
    // Sonntage farblich markieren...
    for (let z = 0; z < zeilenAnzahl; z++) {
        for (const s of sonntagIndices) {
            zeilenElemente[z * 35 + s].classList.add('grid-sunday');
        }
        for (const f of feiertagIndices) {
            zeilenElemente[z * 35 + f.index].classList.add('grid-feiertag');
            zeilenElemente[z * 35 + f.index].title = `${zeilenElemente[z * 35 + f.index].title} (${f.fname})`.trim();
        }
    }
    document.getElementById('mu-title').innerText = `${zeitraum.format('MMMM YYYY')}`;
}

/**
 * Rendert die Mitarbeiterzeilen in die Monatsübersicht inklusive Urlaubsanträge und Schichten
 * @param {dayjs} zeitraum 
 */
async function renderMitarbeiter(zeitraum, schichtenMonat) {
    // Alte Reihen löschen...
    console.log(schichtenMonat.length);
    document.querySelectorAll('.grid-item:not(.grid-legend, .grid-filter)').forEach((item) => item.remove());
    // Mitarbeiter Liste (sortiert nach Nachnamen...)
    let mitarbeiterliste = await stammdatenService.holeMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette, zeitraum.format('YYYY-MM-DD'));
    mitarbeiterliste = filterListeActiveMA(mitarbeiterliste);
    mitarbeiterliste = mitarbeiterliste.filter((ma) => !ma?.Archiviert);
    sortMonatsuebersichtMitarbeiter(mitarbeiterliste);
    const gridTemplateMA = document.querySelector('[grid-row-template]');
    const gridMonat = document.querySelector('.grid-container');
    mitarbeiterliste.forEach((ma) => {
        const newRow = gridTemplateMA.content.cloneNode(true);
        newRow.querySelector('.grid-name').innerText = `${ma.Nachname}, ${ma.Vorname}`;
        newRow.querySelector('.grid-name').title = `${ma.Nachname}, ${ma.Vorname} (${ma.Personalnummer})`;
        newRow.querySelector('.grid-name').addEventListener('click', () => {
            window.myHandlers.selectMA(ma._id);
        });
        newRow.querySelector('.grid-urlaub').innerText = `${ma.Resturlaub}`;
        newRow.querySelector('.grid-krank').innerText = `${ma.Kranktage}`;
        newRow.querySelector('.grid-gz').innerText = `${formatNumber2Decimals(ma.Ueberstunden, false)}`;
        [...newRow.children].forEach((child) => {
            child.setAttribute('data-posten', _.pluck(ma.Posten, 'PostenID').join(','));
            child.setAttribute('data-ma', ma._id);
        });
        renderUrlaubzeitraumMA(ma, newRow, zeitraum);
        const schichtenMA = schichtenMonat.filter((schicht) => _.isEqual(schicht.MitarbeiterID, ma._id));
        renderSchichten(schichtenMA, newRow);
        gridMonat.appendChild(newRow);
    });
}

function sortMonatsuebersichtMitarbeiter(mitarbeiterliste) {
    switch(window.myVars.monatsuebersichtSortType) {
        case 1:
            // Nachname
            mitarbeiterliste.sort((a, b) => (a.Nachname + a.Vorname).localeCompare((b.Nachname + b.Vorname)));
            break;
        case 2:
            // PNR
            mitarbeiterliste.sort((a, b) => {
                if (a.Personalnummer === '') {
                    return 1; // Move empty strings to the end
                } else if (b.Personalnummer === '') {
                    return -1; // Move empty strings to the end
                } else {
                    const numA = parseInt(a.Personalnummer, 10);
                    const numB = parseInt(b.Personalnummer, 10);
                    // falls eine Pnr keine reine Nummer ist, müssen wir diese anders behandeln...
                    if (isNaN(numA) || isNaN(numB)) {
                        return isNaN(numA) ? 1 : -1;
                    }
                    return numA - numB;
                }
            });
            break;
        case 3:
            // Sortierreihenfolge
            mitarbeiterliste.sort((a, b) => a.Sortierreihenfolge - b.Sortierreihenfolge || (a.Nachname + a.Vorname).localeCompare((b.Nachname + b.Vorname)));
            break;
    }
}

/**
 * Rendert den Mitarbeiter Urlaub in die Mitarbeiter Zeile der Monatsübersicht
 * @param {*} mitarbeiter kleines Mitarbeiterobjekt aus der Mitarbeiterliste
 * @param {HTMLElement} maZeile 
 * @param {dayjs} zeitraum 
 */
function renderUrlaubzeitraumMA(mitarbeiter, maZeile, zeitraum) {
    const urlaubsantraege = mitarbeiter.Urlaubsantraege;
    const urlaubtemplate = document.querySelector('[mu-urlaub-hover-template]');
    urlaubsantraege.forEach((urlaub) => {
        // Wir checken ob der Urlaub in diesem Monatszeitraum fällt, wenn nicht skippen wir...
        const urlaubImZeitraum = zeitraum.isSame(urlaub.UrlaubVon, 'month') || zeitraum.isSame(urlaub.UrlaubBis, 'month');
        if (!urlaubImZeitraum || urlaub.UrlaubStatus === 'abgelehnt') return;
        const urlaubAnfang = dayjs.max(zeitraum.startOf('month'), dayjs(urlaub.UrlaubVon));
        const urlaubEnde = dayjs.min(zeitraum.endOf('month'), dayjs(urlaub.UrlaubBis));
        const zeileArray = [...maZeile.children];
        const istOffenerUrlaubsantrag = urlaub.UrlaubStatus == 'offen';
        const urlaubTitle = `${istOffenerUrlaubsantrag ? 'Offener' : 'Genehmigter'} Urlaubsantrag vom ${dayjs(urlaub.UrlaubVon).format('DD.MM.')} - ${dayjs(urlaub.UrlaubBis).format('DD.MM.YYYY')}`;
        for (let date = urlaubAnfang.date(); date <= urlaubEnde.date(); date++) {
            // +3 wegen den ersten 4 Spalten [0, 1, 2, 3] => damit ist der 1. des Monats bei 4
            const tagItem = zeileArray[date + 3];
            tagItem.innerHTML = '<i class="bi bi-airplane-fill rotate45"></i>';
            tagItem.style.backgroundColor = istOffenerUrlaubsantrag ? 'var(--custom-yellow)' : 'var(--custom-green)';
            tagItem.title = urlaubTitle;
            tagItem.setAttribute('data-urlaub', istOffenerUrlaubsantrag ? '0' : '1');
            tagItem.setAttribute('urlaub-id', urlaub._id);
            tagItem.setAttribute('data-count', '1');
            tagItem.classList.add('mu-urlaubsantrag');
            // Hover effekt zum genehmigen/ablehnen
            if (istOffenerUrlaubsantrag) {
                // Falls es offene urlaubsanträge gibt, erstellen eine Bearbeitungsfunktion mit einem Hover Effekt
                const urlaubInfo = urlaubtemplate.content.cloneNode(true).children[0];
                const urlaubZeitraum = urlaubInfo.children[0];
                urlaubZeitraum.innerText = `Offener Urlaubsantrag vom ${dayjs(urlaub.UrlaubVon).format('DD.MM.')} - ${dayjs(urlaub.UrlaubBis).format('DD.MM.YYYY')}`;
                const aktionen = urlaubInfo.children[1];
                const aktionJA = aktionen.children[0];
                const aktionNein = aktionen.children[1];
                aktionJA.addEventListener('click', async () => {
                    const result = await schichtService.erstelleUrlaubAusAntrag(mitarbeiter._id, urlaub._id, 'genehmigt');
                    if (!result) return;
                    // Bei Erfolg Urlaub in grün umwandeln
                    document.querySelectorAll(`[urlaub-id="${urlaub._id}"]`).forEach((item) => {
                        item.setAttribute('data-urlaub', '1');
                        item.title =`Genehmigter Urlaubsantrag vom ${dayjs(urlaub.UrlaubVon).format('DD.MM.')} - ${dayjs(urlaub.UrlaubBis).format('DD.MM.YYYY')}`;
                        item.style.backgroundColor = 'var(--custom-green)';
                        item.classList.remove('mu-urlaub-offen');
                        item.querySelector('.mu-urlaub-div').remove();
                    });
                });
                aktionNein.addEventListener('click', async () => {
                    const result = await stammdatenService.ablehnenUrlaubsantrag(mitarbeiter._id, urlaub._id);
                    if (!result) return;
                    // Bei Erfolg Urlaub entfernen
                    document.querySelectorAll(`[urlaub-id="${urlaub._id}"]`).forEach((item) => {
                        item.innerHTML = '';
                        item.title = '';
                        item.style.backgroundColor = 'unset';
                        const dataCount = item.getAttribute('data-count');
                        item.setAttribute('data-count', dataCount ? parseInt(dataCount) - 1 : 0);
                        item.setAttribute('data-urlaub', '-1');
                    });
                });
                tagItem.classList.add('mu-urlaub-offen');
                tagItem.appendChild(urlaubInfo);
            }
        }
    });
}

function renderSchichten(schichten, maZeile) {
    const zeileArray = [...maZeile.children];
    schichten.forEach((schicht) => {
        const schichtDatum = dayjs(schicht.Datum);
        const tagItem = zeileArray[schichtDatum.date() + 3];
        // Wenn wir den Tag nicht finden überspringen wir ihn...
        if (!tagItem || (tagItem.innerHTML !== '' && schicht.Zeitbloecke[0].EgfzID === Egfz.Urlaub)) return;
        tagItem.innerHTML = tagItem.innerHTML ? tagItem.innerHTML : '<i class="bi bi-clock-fill"></i>';
        // Unterscheide Zeiterfassung und EGFZ Zeiten
        if (schicht.ZeitTyp > ZeitTyp.EGFZ) {
            // Zeiterfassung (manuell oder stempel)
            tagItem.title = `${tagItem.title} ${schicht?.Titel ? schicht.Titel : 'Arbeit'} (${dayjs(schicht.SchichtVon).format('HH:mm')} - ${dayjs(schicht.SchichtBis).format('HH:mm')})`.trim();
            tagItem.style.backgroundColor = 'var(--third-color)';
            tagItem.setAttribute('data-arbeit', '1');
        } else if (schicht.ZeitTyp == ZeitTyp.EGFZ) {
            // EGFZ Zeiten (weitere Unterscheidung zwischen GZ+- und EGFZ)
            if (schicht.Zeitbloecke[0].EgfzID === Egfz.GZAufbau) {
                tagItem.style.backgroundColor = 'var(--custom-light-orange)';
                tagItem.title = `${tagItem.title} GZ+ (${dayjs(schicht.SchichtVon).format('HH:mm')} - ${dayjs(schicht.SchichtBis).format('HH:mm')})`.trim();
                tagItem.setAttribute('data-arbeit', '1');
            } else if (schicht.Zeitbloecke[0].EgfzID === Egfz.GZAbbau) {
                tagItem.style.backgroundColor = 'var(--custom-light-pink)';
                tagItem.title = `${tagItem.title} GZ- (${dayjs(schicht.SchichtVon).format('HH:mm')} - ${dayjs(schicht.SchichtBis).format('HH:mm')})`.trim();
                tagItem.setAttribute('data-arbeit', '1');
            } else {
                tagItem.title = `${tagItem.title} ${schicht?.Titel ? 'EGFZ ' + schicht.Titel : 'EGFZ'}`;
                tagItem.style.backgroundColor = 'var(--primary-color)';
                tagItem.style.color = 'white';
                if ([Egfz.Krank, Egfz.UnentschuldigtKrank, Egfz.Krank6Wochen, Egfz.KindKrank, Egfz.KO, Egfz.u4K, Egfz.KrankKurzarbeit].includes(schicht.Zeitbloecke[0].EgfzID)) {
                    tagItem.innerHTML = '<i class="bi bi-clipboard-plus"></i>';
                    tagItem.setAttribute('data-krank', '1');
                } else if (schicht.Zeitbloecke[0].EgfzID === Egfz.Schule) {
                    tagItem.innerHTML = '<i class="bi bi-book"></i>';
                    tagItem.setAttribute('data-schule', '1');
                } else {
                    tagItem.innerHTML = '<i class="bi bi-slash-circle-fill"></i>';
                    tagItem.setAttribute('data-egfz', '1');
                }
            }
        } else if (schicht.ZeitTyp == ZeitTyp.Dienstplan) {
            // Dienstplan
            tagItem.title = `${tagItem.title} ${schicht?.Titel ? schicht.Titel : 'Dienstplan'} (${dayjs(schicht.SchichtVon).format('HH:mm')} - ${dayjs(schicht.SchichtBis).format('HH:mm')})`.trim();
            tagItem.style.backgroundColor = 'var(--secondary-color)';
            tagItem.style.color = 'white';
            tagItem.innerHTML = '<i class="bi bi-calendar-week"></i>';
            tagItem.setAttribute('data-dienst', '1');
        }
        // Allgemein Schicht Counter
        const tagCounter = tagItem.getAttribute('data-count');
        const newCount = tagCounter ? parseInt(tagCounter) + 1 : '1';
        tagItem.setAttribute('data-count', newCount);
        if (newCount == 2) {
            const bubble = document.createElement('div');
            bubble.innerText = '2';
            bubble.classList.add('grid-row-bubble');
            tagItem.appendChild(bubble);
        }
        if (newCount > 2) {
            const bubble = tagItem.querySelector('.grid-row-bubble');
            bubble.innerText = newCount;
        }
    });
}

/**
 * Sortiert die Monatsübersicht nach:
 * - 1: Nachname
 * - 2: Personalnummer
 * - 3: Sortierreihenfolge
 * @param {Number} sortType
 */
async function sortMonatsuebersicht(sortType) {
    window.myVars.monatsuebersichtSortType = sortType;
    await ladeMonatsuebersicht();
}

export {
    ladeMonatsuebersicht
};
