import _ from 'underscore';
import systemNachrichtService from './systemNachrichtService.js';
import stammdatenService from './stammdatenService.js';
import dayjs from '../../../../shared/services/dayjs.js';

class SchichtService {
    // Speicher Request an den Server für eine Schicht
    async speichereSchicht(schichtObjekt, ignoreSucessMsg = false) {
        try {
            // wenn wir eine neue Schicht erstellen, ist eine POST Request
            const istNeu = _.isEmpty(schichtObjekt._id);
            const response = await fetch('/neolohn/api/zeiten/schichten/', {
                method: istNeu ? 'POST' : 'PUT',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(schichtObjekt),
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            ignoreSucessMsg ? '' : systemNachrichtService.zeigeKleineNachricht('Erfolgreich gespeichert!', 1);
            await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Speichern!', -1);
            return false;
        }
    }

    // Liest eine Schicht aus
    async leseSchicht(schichtID) {
        try {
            const response = await fetch(`/neolohn/api/zeiten/schichten/${schichtID}`);
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.error(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Lesen der Schicht!', -1);
            return false;
        }
    }

    // Lese Request für alle Schichten des ausgewählten Mitarbeiters eines Zeitraums
    async leseAlleSchichtenMitarbeiter(datumVon, datumBis, mitarbeiterID) {
        // standardmäßig geben wir den aktuellen Monat an
        if (!datumVon && !datumBis) {
            datumVon = dayjs(window.myVars.aktuellesMonatsDatum).startOf('month');
            datumBis = dayjs(window.myVars.aktuellesMonatsDatum).endOf('month');
        }
        try {
            const response = await fetch(`/neolohn/api/zeiten/mitarbeiter/${mitarbeiterID}?StartDatum=${datumVon.toISOString()}&EndDatum=${datumBis.toISOString()}`);
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Schichten!', -1);
            console.error(error);
            return false;
        }
    }

    // Lösch Request für eine bestimmte Schicht
    async zeitLoeschen(schichtID) {
        try {
            const response = await fetch(`/neolohn/api/zeiten/schichten/${schichtID}`, {
                method: 'DELETE',
                mode: 'cors',
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Erfolgreich gelöscht!', 1);
            // wir holen die Mitarbeiter daten neu, für aktuelle Statuswerte nach Löschen einer Schicht...
            await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Löschen!', -1);
            return false;
        }
    }

    /**
     * Erstellt einen genehmigten Urlaubsantrag und relevante Urlaubsschichten.
     * @param {string} mitarbeiterID objectId
     * @param {Date} urlaubVon
     * @param {Date} urlaubBis
     * @returns true (success) oder false (error)
     */
    async erstelleUrlaubsschichten(mitarbeiterID, urlaubVon, urlaubBis) {
        try {
            document.body.style.cursor = 'wait';
            const body = {
                MitarbeiterID: mitarbeiterID,
                UrlaubVon: urlaubVon,
                UrlaubBis: urlaubBis
            };
            const response = await fetch('/neolohn/api/zeiten/mitarbeiter/urlaub', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Urlaub Erfolgreich gespeichert!', 1);
            document.body.style.cursor = 'auto';
            // wir holen die Mitarbeiter daten neu, für aktuelle Statuswerte nach Urlaubseintragung.
            await stammdatenService.holeMitarbeiter(mitarbeiterID);
            return true;            
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Urlaubsanfrage!', -1);
            document.body.style.cursor = 'auto';
            return false;
        }
    }

    /**
     * Stellt eine DELETE Request an den Server für die Widerrufung der Urlaubsschichten aus dem Antrag
     * @param {ObjectIdString} mitarbeiterID
     * @param {ObjectIdString} urlaubID
     * @param {String} statusUpdate
     * @returns Urlaubsantrag
     */
    async entferneUrlaub(mitarbeiterID, urlaubID, statusUpdate) {
        try {
            const body = {
                MitarbeiterID: mitarbeiterID,
                UrlaubID: urlaubID,
                StatusUpdate: statusUpdate
            };
            const response = await fetch('/neolohn/api/mitarbeiter/urlaub', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag erfolgreich widerrufen!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Urlaubswiderrufung!', -1);
            return false;
        }
    }

    /**
     * Stellt eine POST Request an den Server für die Erstellung der Urlaubsschichten aus dem Antrag
     * @param {ObjectIdString} mitarbeiterID
     * @param {ObjectIdString} urlaubID
     * @param {String} statusUpdate
     */
    async erstelleUrlaubAusAntrag(mitarbeiterID, urlaubID, statusUpdate) {
        try {
            const body = {
                MitarbeiterID: mitarbeiterID,
                UrlaubID: urlaubID,
                StatusUpdate: statusUpdate
            };
            const response = await fetch('/neolohn/api/mitarbeiter/urlaub', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag erfolgreich genehmigt!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Urlaubserstellung!', -1);
            return false;
        }
    }

    /**
     * Importiert Mitarbeiter Zeiten aus E2N
     * @param {string} zeitraum Zeitraum Monat der importiert werden soll...
     */
    async importiereZeitenE2N(zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch(`/neolohn/api/zeiten/import/e2n?Zeitraum=${zeitraum}`);
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            // Falls es eine Fehlerliste gibt...
            if (result.length > 1) {
                result.sort((a, b) => a.personalnummer.localeCompare(b.personalnummer));
                const importListePopup = window.open('', 'popup', 'toolbar=no, menubar=no, resizable=yes');
                importListePopup.document.title = 'E2N Import Hinweise';
                const fehlerBody = document.createElement('div');
                const fehlerMeldung = document.createElement('h4');
                fehlerMeldung.innerText = 'Folgende Abwesenheiten konnten nicht mit EGFZ zugeordnet werden:';
                fehlerBody.appendChild(fehlerMeldung);
                // table
                const htmlListe = document.createElement('table');
                htmlListe.style.textAlign = 'center';
                fehlerBody.appendChild(htmlListe);
                // table header
                const listHeader = document.createElement('thead');
                const headerRow = document.createElement('tr');
                ['Datum', 'Personalnummer', 'AbwesenheitsTyp/EGFZ', 'Dauer in Minuten'].forEach((header) => {
                    const th = document.createElement('th');
                    th.innerText = header;
                    th.style.border = '1px solid black';
                    th.style.padding = '5px';
                    headerRow.appendChild(th);
                });
                listHeader.appendChild(headerRow);
                htmlListe.appendChild(listHeader);
                
                // table body
                const tbody = document.createElement('tbody');
                result.forEach((fehler) => {
                    const dataRow = document.createElement('tr');
                    [fehler.datum, fehler.personalnummer, fehler.abwesenheitsTyp, fehler.dauer].forEach((data) => {
                        const td = document.createElement('td');
                        td.innerText = data;
                        td.style.border = '1px solid black';
                        td.style.padding = '2.5px 10px';
                        dataRow.appendChild(td);
                    });
                    tbody.appendChild(dataRow);
                });
                htmlListe.appendChild(tbody);
                importListePopup.document.body.innerHTML = fehlerBody.outerHTML.toString();
                importListePopup.focus();
            }
            systemNachrichtService.zeigeKleineNachricht('Zeitenimport erfolgreich!', 1);
            window.myHandlers.toggleLoader();
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Zeitenimport fehlgeschalgen!', -1);
            window.myHandlers.toggleLoader();
        }
    }
    
    /**
     * Sendet eine Serveranfrage für die monatliche Gutzeitautomatisierung...
     * @param {string} mitarbeiterID 
     * @param {string} zeitraum 
     * @returns 
     */
    async erstelleMonatlicheGutzeitMA(mitarbeiterID, zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch('/neolohn/api/zeiten/schichten/gutzeit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Zeitraum: zeitraum,
                    MitarbeiterID: mitarbeiterID
                })
            });
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Gutzeit erfolgreich eingetragen!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Anfrage fehlgeschalgen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    async leseAlleSchichtenMonatsuebersicht(zeitraum, betriebsstaetteID) {
        try {
            const urlParams = new URLSearchParams({ Zeitraum: zeitraum, BetriebsstaetteID: betriebsstaetteID });
            const response = await fetch(`/neolohn/api/zeiten/monat?${urlParams.toString()}`);
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Zeiten!', -1);
            return [];
        }
    }
}

const schichtService = new SchichtService();
export default schichtService;
