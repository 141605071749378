class BereichPostenFilter {
    constructor(bereich, filterFunction) {
        this.bereich = bereich;
        this.posten = bereich.PostenInBereich;
        this.filter = filterFunction;
    }

    template() {
        return `
            <div id="${this.bereich.BereichID}" class="tu-filter-parent tu-filter-parent-open">
                <div class="tu-filter-name">${this.bereich.Bereich}</div>
                <input class="tu-filter-parent-input" style="width: 16px; margin-right: 20px;" type="checkbox" onclick="event.stopPropagation()" checked>
            </div>
            ${this.posten.map((p) => `
                <div id="${p.PostenID}" class="tu-filter-child">
                    <div class="tu-filter-name">${p.Posten}</div>
                    <input class="tu-filter-child-input" style="width: 16px;" type="checkbox" onclick="event.stopPropagation()" checked>
                </div>    
            `).join('')}
        `;
    }
    
    render() {
        // <div class="tu-filter-item">
        const filterItem = document.createElement('div');
        filterItem.className = 'tu-filter-item';
        filterItem.insertAdjacentHTML('afterbegin', this.template());
        // add event handlers
        const bereichParent = filterItem.querySelector('.tu-filter-parent');
        bereichParent.addEventListener('click', (event) => this.clickFilterParent(bereichParent, event));
        bereichParent.addEventListener('input', (event) => this.inputFilterParent(bereichParent, event, filterItem, this.filter));
        filterItem.querySelectorAll('.tu-filter-child').forEach((postenChild) => {
            const childInput = postenChild.querySelector('.tu-filter-child-input');
            childInput.addEventListener('input', (event) => {
                event.stopPropagation();
                this.filter();
            });
            // Wir wollen den Haken auch setzen, wenn wir auf den Posten klicken.
            postenChild.addEventListener('click', () => {
                childInput.checked = !childInput.checked;
                this.filter();
            });
        });

        // add to filter list
        const filterList = document.querySelector('.tu-filter-list');
        filterList.appendChild(filterItem);
    }
    
    /**
     * Klappt den Bereich Filter auf oder zu
     * @param {HTMLElement} thisElement 
     * @param {*} event 
     */
    clickFilterParent(thisElement, event) {
        event.stopPropagation();
        thisElement.parentNode.querySelectorAll('.tu-filter-child').forEach((child) => {
            child.classList.toggle('hidden');
        });
        const className = 'tu-filter-parent-open';
        thisElement.classList.toggle(className);
    }
    
    inputFilterParent(thisElement, event, filterItem, filterFunction) {
        event.stopPropagation();
        filterItem.querySelectorAll('.tu-filter-child').forEach((child) => {
            child.querySelector('.tu-filter-child-input').checked = thisElement.querySelector('input').checked;
        });
        filterFunction();
    }
}


export {
    BereichPostenFilter
};
